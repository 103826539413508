import { render, staticRenderFns } from "./blogger_promotion_set.vue?vue&type=template&id=2ff9962c&scoped=true"
import script from "./blogger_promotion_set.vue?vue&type=script&lang=ts"
export * from "./blogger_promotion_set.vue?vue&type=script&lang=ts"
import style0 from "./blogger_promotion_set.vue?vue&type=style&index=0&id=2ff9962c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff9962c",
  null
  
)

export default component.exports