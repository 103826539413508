
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getPromoteBloggerCfgList, setPromoteBloggerCfg } from "@/api/request/promotion";

//组件
@Component({
  name: "bloggerPromotionSet",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private total_user: number = 0;
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_idStr: "", //ID

    //请求数据
    user_id: 0, //ID
    user_name: "", //用户名
    nick_name: "", //用户昵称
  };

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //其他数据
    this.listQuery.user_id = Number(this.listQuery.user_idStr) > 0 ? Number(this.listQuery.user_idStr) : undefined;

    //获取数据
    const { data } = await getPromoteBloggerCfgList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.total_user = data.total_user;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }
  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理修改
  private handleChange(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      max_user_str: String(row.max_user), //人数上限

      //数据
      id: row.id, //状态
      user_id: row.user_id, //用户ID
      max_user: row.max_user, //人数上限
    };

    //显示界面
    this.dialogShow = true;
  }

  //------------------------ 修改 ------------------------
  //表单数据
  private form: any = {
    //临时数据
    max_user_str: "0", //人数上限

    //数据
    id: 1, //状态
    user_id: 0, //用户ID
    max_user: 0, //人数上限
  };

  //定义变量
  private dialogShow: boolean = false; //显示随画框
  private dialogLoading: boolean = false; //对话框等待

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //修改数据
  private async changeData() {
    //数据处理
    if (Number(this.form.max_user_str) == 0) return this.$message.error("请输入正确的人数上限");

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.form.max_user = Number(this.form.max_user_str);

    //设置
    await setPromoteBloggerCfg(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogShow = false;

      //获取数据
      this.getList();
    }, 0.5 * 1000);
  }
}
